<template>
  <div class="container-fluid">
    <div class="card-header" style="border-color: var(--bordercolorform)">
      <div class="card-title">
        <h3 class="card-label cardLabelTiket"> URL Aplikasi</h3>
      </div>
    </div>
    <b-form-group style="margin: 12px">
      <b-form-radio
        size="lg"
        stacked
        v-model="urlSelected"
        checked
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="https://zipato.sisnet.my.id/zipato-web/app2login"
        ><strong> Aplikasi Visit </strong></b-form-radio
      >
      <b-form-radio
        size="lg"
        stacked
        v-model="urlSelected"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="https://zipato.sisnet.my.id/zipato-web/app2login"
        ><strong> Aplikasi Visitor </strong></b-form-radio
      >
    </b-form-group>
    <b-button class="mt-3 mb-5" variant="danger" @click="GoToUrl"
      >Go To Link</b-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlSelected: "",
    };
  },
  methods: {
    GoToUrl() {
      window.open(this.urlSelected);
      this.$bvModal.hide("bv-modal-example");
    },
  },
};
</script>

<style>
</style>