<template>
  <div class="container-fluid ml-8">
    <b-card no-body style="border-color: transparent">
      <Url />
    </b-card>
  </div>
</template>

<script>
import Url from "../../components/url/url.vue";

export default {
  components: { Url },
};
</script>

<style >
.card-header-tabs {
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.full-width .nav-tabs .nav-item {
  margin-bottom: -1px;
  flex-grow: 1 !important;
  text-align: center !important;
}
</style>
